import "core-js/modules/es.array.iterator";
import "core-js/modules/es.map";
import "core-js/modules/es.object.assign";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
var homes = new Map();
export var portal = {
  inserted: function inserted(el, _ref, vnode) {
    var value = _ref.value;
    var parentNode = el.parentNode;
    var home = document.createComment('');
    var hasMovedOut = false;

    if (value !== false) {
      parentNode.replaceChild(home, el); // moving out, el is no longer in the document

      document.getElementById('homePage').appendChild(el); // moving into new place

      hasMovedOut = true;
    }

    homes.set(el, {
      parentNode: parentNode,
      home: home,
      hasMovedOut: hasMovedOut
    }); // remember where home is or should be
  },
  componentUpdated: function componentUpdated(el, _ref2) {
    var value = _ref2.value;

    // need to make sure children are done updating (vs. `update`)
    var _homes$get = homes.get(el),
        parentNode = _homes$get.parentNode,
        home = _homes$get.home,
        hasMovedOut = _homes$get.hasMovedOut; // recall where home is


    if (!hasMovedOut && value) {
      // remove from document and leave placeholder
      parentNode.replaceChild(home, el); // append to target

      document.getElementById('homePage').appendChild(el);
      homes.set(el, Object.assign({}, homes.get(el), {
        hasMovedOut: true
      }));
    } else if (hasMovedOut && value === false) {
      // previously moved, coming back home
      parentNode.replaceChild(el, home);
      homes.set(el, Object.assign({}, homes.get(el), {
        hasMovedOut: false
      }));
    }
  },
  unbind: function unbind(el, binding) {
    var _homes$get2 = homes.get(el),
        home = _homes$get2.home,
        hasMovedOut = _homes$get2.hasMovedOut; // recall where home is


    if (hasMovedOut) home.remove();
    el.remove();
    homes.delete(el);
  }
};