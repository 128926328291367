import auth, { getDefaultState as getDefaultAuthState } from './modules/auth';
import profile, { getDefaultState as getDefaultProfileState } from './modules/profile';
import events, { getDefaultState as getDefaultEventsState } from './modules/events';
import userFollowing, { getDefaultState as getDefaultUserFollowingState } from './modules/userFollowing';
import notifications, { getDefaultState as getDefaultNotificationsState } from './modules/notifications';
import modalPick, { getDefaultState as getDefaultModalPickState } from './modules/modalPick';
import wallet, { getDefaultState as getDefaultWalletState } from './modules/wallet';
import picks, { getDefaultState as getDefaultPicksState } from './modules/picks';
import banners, { getDefaultState as getDefaultBannersState } from './modules/banners';
import newsfeed, { getDefaultState as getDefaultNewsfeedState } from './modules/newsfeed';
import userBlocking, { getDefaultState as getDefaultUserBlockingState } from './modules/userBlocking';
import literals, { getDefaultState as getDefaultLiteralsdState } from './modules/literals';
export default {
  strict: true,
  state: {
    routeLoading: false,
    routeLoadingStartTimeoutId: undefined,
    routeLoadingEndTimeoutId: undefined
  },
  modules: {
    auth: auth,
    profile: profile,
    events: events,
    userFollowing: userFollowing,
    notifications: notifications,
    modalPick: modalPick,
    wallet: wallet,
    picks: picks,
    banners: banners,
    newsfeed: newsfeed,
    userBlocking: userBlocking,
    literals: literals
  },
  mutations: {
    setRouteLoading: function setRouteLoading(state, v) {
      state.routeLoading = !!v;
    },
    routeLoadingStart: function routeLoadingStart(state, _ref) {
      var routeLoadingStartTimeoutId = _ref.routeLoadingStartTimeoutId;
      state.routeLoadingStartTimeoutId = routeLoadingStartTimeoutId;
    },
    routeLoadingEnd: function routeLoadingEnd(state, _ref2) {
      var routeLoadingEndTimeoutId = _ref2.routeLoadingEndTimeoutId;
      state.routeLoadingEndTimeoutId = routeLoadingEndTimeoutId;
    },
    resetState: function resetState(state) {
      state.auth = getDefaultAuthState();
      state.profile = getDefaultProfileState(); // state.events = getDefaultEventsState();

      state.userFollowing = getDefaultUserFollowingState();
      state.notifications = getDefaultNotificationsState();
      state.modalPick = getDefaultModalPickState();
      state.wallet = getDefaultWalletState();
      state.picks = getDefaultPicksState(); // state.banners = getDefaultBannersState();

      state.userBlocking = getDefaultUserBlockingState(); // state.newsfeed = getDefaultNewsfeedState();
    }
  },
  actions: {
    routeLoadingStart: function routeLoadingStart(_ref3) {
      var state = _ref3.state,
          commit = _ref3.commit;
      clearTimeout(state.routeLoadingStartTimeoutId);
      clearTimeout(state.routeLoadingEndTimeoutId);
      commit('routeLoadingStart', {
        routeLoadingStartTimeoutId: setTimeout(function () {
          commit('setRouteLoading', true);
        }, 500)
      });
    },
    routeLoadingEnd: function routeLoadingEnd(_ref4) {
      var state = _ref4.state,
          commit = _ref4.commit,
          dispatch = _ref4.dispatch,
          getters = _ref4.getters;
      clearTimeout(state.routeLoadingStartTimeoutId);
      clearTimeout(state.routeLoadingEndTimeoutId);
      commit('routeLoadingEnd', {
        routeLoadingEndTimeoutId: setTimeout(function () {
          commit('setRouteLoading', false);
        }, 500)
      });

      if (getters['auth/isAuthenticated']) {
        dispatch('notifications/check');
        dispatch('wallet/fetchBalance');
      }
    }
  }
};