import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives';

Vue.use(Vuetify);

var opts = {
  theme: {
    themes: {
      light: {
        primary: '#14A757',
        secondary: '#009343',
        current: '#ffffff',
        accent: '#CDCDCD',
        error: '#E91B2E',
      },
    },
  },
  directives: {
    Ripple,
  },
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
};

export default new Vuetify(opts);
