import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.promise";
import "core-js/modules/web.dom-collections.for-each";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { PAYPAL_OAUTH_REDIRECT_PATH } from '@services/constants';

var AuthPage = function AuthPage() {
  return import('@comp/pages/AuthPage.vue');
};

var Join = function Join() {
  return import('@comp/pages/auth/Join.vue');
};

var SingIn = function SingIn() {
  return import('@comp/pages/auth/SingIn.vue');
};

var PasswordReset = function PasswordReset() {
  return import('@comp/pages/auth/PasswordReset.vue');
};

var PasswordResetSuccess = function PasswordResetSuccess() {
  return import('@comp/pages/auth/PasswordResetSuccess.vue');
};

var ChangePassword = function ChangePassword() {
  return import('@comp/pages/auth/ChangePassword.vue');
};

var CreateAccount = function CreateAccount() {
  return import('@comp/pages/auth/CreateAccount.vue');
};

var TellMore = function TellMore() {
  return import('@comp/pages/auth/TellMore.vue');
};

var Reactivate = function Reactivate() {
  return import('@comp/pages/auth/Reactivate.vue');
};

var HomePage = function HomePage() {
  return import('@comp/pages/Home.vue');
};

var MakingPicks = function MakingPicks() {
  return import('@comp/pages/MakingPicks.vue');
};

var UserPage = function UserPage() {
  return import('@comp/pages/user/UserPage.vue');
};

var UserPageHome = function UserPageHome() {
  return import('@comp/pages/user/UserPageHome.vue');
};

var UserPageFollowers = function UserPageFollowers() {
  return import('@comp/pages/user/UserPageFollowers.vue');
};

var BuyingPicks = function BuyingPicks() {
  return import('@comp/pages/BuyingPicks.vue');
};

var Newsfeed = function Newsfeed() {
  return import('@comp/pages/Newsfeed.vue');
};

var NewsfeedPost = function NewsfeedPost() {
  return import('@comp/pages/NewsfeedPost.vue');
};

var UserProfile = function UserProfile() {
  return import('@comp/pages/UserProfile.vue');
};

var UserProfileInfo = function UserProfileInfo() {
  return import('@comp/pages/user-profile/UserProfileInfo.vue');
};

var UserProfileNotification = function UserProfileNotification() {
  return import('@comp/pages/user-profile/UserProfileNotification.vue');
};

var UserProfileSettings = function UserProfileSettings() {
  return import('@comp/pages/user-profile/UserProfileSettings.vue');
};

var UserProfileRefer = function UserProfileRefer() {
  return import('@comp/pages/user-profile/UserProfileRefer.vue');
};

var UserProfileSupport = function UserProfileSupport() {
  return import('@comp/pages/user-profile/UserProfileSupport.vue');
};

var UserProfileSidebarSettings = function UserProfileSidebarSettings() {
  return import('@comp/pages/user-profile/UserProfileSidebarSettings.vue');
};

var ManageFunds = function ManageFunds() {
  return import('@comp/pages/user-profile/ManageFunds.vue');
};

var AddFunds = function AddFunds() {
  return import('@comp/pages/user-profile/AddFunds.vue');
};

var WithdrawFunds = function WithdrawFunds() {
  return import('@comp/pages/user-profile/WithdrawFunds.vue');
};

var Search = function Search() {
  return import('@comp/pages/Search.vue');
};

var Leaderboard = function Leaderboard() {
  return import('@comp/pages/Leaderboard.vue');
};

var SuggestedUsers = function SuggestedUsers() {
  return import('@comp/pages/SuggestedUsers.vue');
};

export default [{
  path: '/',
  component: HomePage,
  children: [{
    path: '/',
    name: 'Dashboard',
    component: BuyingPicks,
    props: function props(r) {
      return {
        withShowMoreBtn: true
      };
    },
    meta: {
      className: 'dashboard-page'
    }
  }, {
    path: '/newsfeed',
    name: 'Newsfeed',
    component: Newsfeed,
    meta: {
      className: 'newsfeed-page'
    }
  }, {
    path: '/pick-of-the-day',
    name: 'POTD',
    component: Newsfeed,
    meta: {
      className: 'newsfeed-page'
    }
  }, {
    path: '/newsfeed-post/:postId',
    name: 'NewsfeedPost',
    component: NewsfeedPost,
    meta: {
      className: 'newsfeed-post-page'
    }
  }, {
    path: '/picks',
    name: 'BuyingPicksMobile',
    component: BuyingPicks,
    meta: {
      className: 'picks-mobile-page'
    },
    props: function props(r) {
      return {
        hideBanner: true
      };
    }
  }, {
    path: '/make-picks/:league?',
    name: 'MakingPicks',
    component: MakingPicks,
    meta: {
      className: 'make-picks-page'
    }
  }, {
    path: '/user/:userName',
    component: UserPage,
    meta: {
      className: 'user-page'
    },
    props: function props(r) {
      return {
        userName: r.params.userName
      };
    },
    children: [{
      path: '/',
      name: 'UserPage',
      component: UserPageHome,
      props: function props(r) {
        return {
          userName: r.params.userName
        };
      }
    }, {
      path: ':followerType(following|followers)',
      name: 'UserPageFollowers',
      component: UserPageFollowers,
      props: function props(r) {
        return {
          userName: r.params.userName,
          followerType: r.params.followerType
        };
      }
    }]
  }, {
    path: '/profile',
    component: UserProfile,
    meta: {
      className: 'user-profile',
      auth: true
    },
    children: [{
      path: '/',
      name: 'UserProfile',
      component: UserProfileInfo
    }, {
      path: 'notifications/:group(users|made-picks|follower-picks)?',
      name: 'Notifications',
      component: UserProfileNotification,
      props: function props(_ref) {
        var group = _ref.params.group,
            settings = _ref.query.settings;
        return {
          group: group === 'users' ? 'users' : group === 'made-picks' ? 'madePicks' : group === 'follower-picks' ? 'followerPicks' : 'all',
          settings: settings === 'true'
        };
      }
    }, {
      path: 'settings',
      name: 'ProfileSettings',
      component: UserProfileSettings
    }, {
      path: 'referFriend',
      name: 'ReferFriend',
      component: UserProfileRefer
    }, {
      path: 'support',
      name: 'Support',
      component: UserProfileSupport
    }, {
      path: 'manageFunds',
      name: 'ManageFunds',
      component: ManageFunds
    }, {
      path: 'addFunds',
      name: 'addFunds',
      component: AddFunds
    }, {
      path: 'withdrawFunds/:target(pay-pal|stripe)?',
      name: 'withdrawFunds',
      component: WithdrawFunds,
      props: function props(r) {
        return {
          redirectUrl: r.query.r
        };
      }
    }, {
      path: 'menu',
      name: 'UserProfileSidebarSettings',
      component: UserProfileSidebarSettings
    }]
  }, {
    path: '/search/:searchType(events|picks|users)?',
    name: 'Search',
    component: Search,
    meta: {
      className: 'search-page'
    },
    props: function props(r) {
      return {
        searchType: r.params.searchType || 'all',
        searchQuery: r.query.q
      };
    }
  }, {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: Leaderboard,
    meta: {
      className: 'leader-board'
    }
  }, {
    path: '/hot-board',
    name: 'Hotboard',
    component: Leaderboard,
    meta: {
      className: 'leader-board'
    }
  }, {
    path: '/suggested-users',
    name: 'SuggestedUsers',
    component: SuggestedUsers,
    meta: {
      auth: true
    }
  }]
}, {
  path: '/unsubscribe',
  name: 'Unsubscribe',
  meta: {
    auth: true
  }
}, {
  path: PAYPAL_OAUTH_REDIRECT_PATH,
  name: 'PayPalConnect',
  meta: {
    auth: true,
    payPalConnect: true
  }
}, {
  path: '/auth',
  component: AuthPage,
  meta: {
    className: 'start-page'
  },
  children: [{
    path: '/',
    name: 'Join',
    component: Join,
    meta: {
      className: 'start-page',
      auth: false
    },
    props: function props(r) {
      var redirectUrl = r.query.r;
      var userRefer = r.query.userRefer;
      var oauthCallbackParameters = undefined;

      if (r.query.code && r.query.state) {
        oauthCallbackParameters = {
          code: r.query.code,
          state: r.query.state
        };
      } else if (r.query.oauth_token && r.query.oauth_verifier && r.query.state) {
        oauthCallbackParameters = {
          oauth_token: r.query.oauth_token,
          oauth_verifier: r.query.oauth_verifier,
          state: r.query.state
        };
      }

      return {
        redirectUrl: redirectUrl,
        oauthCallbackParameters: oauthCallbackParameters,
        userRefer: userRefer
      };
    }
  }, {
    path: 'sing_in',
    name: 'SingIn',
    component: SingIn,
    meta: {
      className: 'start-page singin-page',
      passwordChanged: true,
      auth: false
    },
    props: function props(r) {
      var redirectUrl = r.query.r;

      var oauthCallbackParameters = _objectSpread({}, r.query);

      delete oauthCallbackParameters.r;
      if (Object.keys(oauthCallbackParameters).length === 0) oauthCallbackParameters = undefined;
      return {
        redirectUrl: redirectUrl,
        oauthCallbackParameters: oauthCallbackParameters
      };
    }
  }, {
    path: 'logout',
    name: 'Logout',
    meta: {
      logout: true
    }
  }, {
    path: 'password_forgot',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: {
      className: 'start-page password-reset-page'
    },
    props: function props(r) {
      return {
        redirectUrl: r.query.r
      };
    }
  }, {
    path: 'password_reset',
    name: 'PasswordResetSuccess',
    component: PasswordResetSuccess,
    meta: {
      className: 'start-page password-reset-success-page'
    },
    props: function props(r) {
      return {
        email: r.query.email,
        redirectUrl: r.query.r
      };
    }
  }, {
    path: 'change_password',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      className: 'start-page change-password-page'
    },
    props: function props(r) {
      return {
        code: r.query.passwordResetToken,
        userId: parseInt(r.query.passwordResetUserId),
        redirectUrl: r.query.r
      };
    }
  }, {
    path: 'create_account',
    name: 'CreateAccount',
    component: CreateAccount,
    meta: {
      className: 'start-page create-account-page',
      auth: false
    },
    props: function props(r) {
      return {
        redirectUrl: r.query.r
      };
    }
  }, {
    path: 'profile/:group(interests)?',
    name: 'TellMore',
    component: TellMore,
    meta: {
      className: 'user-info-page',
      auth: true,
      hideCarousel: true
    },
    props: function props(r) {
      return {
        group: r.params.group || 'info',
        redirectUrl: r.query.r
      };
    }
  }, {
    path: 'reactivate',
    name: 'Reactivate',
    component: Reactivate,
    meta: {
      className: 'start-page',
      deactivated: true
    }
  }]
}];