//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import SignInForm from '@comp/forms/auth/singIn.vue';
import Button from '@comp/parts/VButton.vue';
export default {
  name: 'SingIn',
  components: {
    SignInForm: SignInForm,
    Button: Button
  },
  props: {
    redirectUrl: String,
    oauthCallbackParameters: Object,
    isPopup: Boolean
  },
  mounted: function mounted() {
    try {
      var elemId = this.$route.params ? this.$route.params.scrollTo : null;

      if (elemId === 'SingIn') {
        var elem = document.getElementById(elemId);
        setTimeout(function () {
          var clientRect = elem.getBoundingClientRect();
          window.scrollTo({
            top: clientRect.y - 60,
            behavior: 'smooth'
          });
        }, 0);
      }
    } catch (err) {}
  },
  methods: {
    showJoin: function showJoin() {
      this.$store.commit('auth/setAuthPopup', {
        show: true,
        view: 'register'
      });
    }
  }
};