'use strict';

import "core-js/modules/es.array.for-each";
import "core-js/modules/web.dom-collections.for-each";
import './../scss/common.scss';
import 'whatwg-fetch';
import 'requestidlecallback-polyfill';

(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('remove')) {
      return;
    }

    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode === null) {
          return;
        }

        this.parentNode.removeChild(this);
      }
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

import '@/js/vue/main.js';