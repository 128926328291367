export function getDefaultState() {
  var defaultState = {
    pick: undefined,
    opened: false,
    searchOpened: true
  };
  return defaultState;
}
export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    open: function open(state, _ref) {
      var pick = _ref.pick;
      if (!pick) throw new Error('Pick required');
      state.pick = pick;
      state.opened = true;
    },
    close: function close(state) {
      state.opened = false;
    },
    onClose: function onClose(state) {
      state.opened = false;
      state.pick = undefined;
    },
    hideSearchHandler: function hideSearchHandler(state, payload) {
      state.searchOpened = !payload;
    }
  }
};