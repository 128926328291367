export var FRONT_URL = process.env.FRONT_URL;
export var API_URL = process.env.API_URL;
export var PICK_MIN_PRICE = 2;
export var PICK_MAX_PRICE = 100;
export var PICK_MIN_UNITS_TO_RISK_OR_WIN = 0.1;
export var PICK_MAX_UNITS_TO_RISK_OR_WIN = 5; // Stripe

export var STRIPE_P_KEY = process.env.STRIPE_P_KEY;
export var STRIPE_CLIENT_ID = process.env.STRIPE_CLIENT_ID;
export var STRIPE = window && window.Stripe ? new Stripe(STRIPE_P_KEY, {
  locale: 'en'
}) : undefined; // Plaid

export var PLAID_ENV = process.env.PLAID_ENV;
export var PLAID_KEY = process.env.PLAID_KEY;
export var PLAID_CLIENT_NAME = 'Flippick'; //Paypal

export var PAYPAL_HOST = process.env.PAYPAL_HOST;
export var PAYPAL_CLIENT_ID = process.env.PAYPAL_CLIENT_ID;
export var PAYPAL_OAUTH_REDIRECT_HOST = process.env.PAYPAL_OAUTH_REDIRECT_HOST;
export var PAYPAL_OAUTH_REDIRECT_PATH = process.env.PAYPAL_OAUTH_REDIRECT_PATH;
export var PAYPAL_OAUTH_REDIRECT_URL = PAYPAL_OAUTH_REDIRECT_HOST + PAYPAL_OAUTH_REDIRECT_PATH;
export var PAYPAL_OAUTH_SCOPE = process.env.PAYPAL_OAUTH_SCOPE; // Tenor api key

export var TENOR_API_KEY = 'F0K2614HVF3Q';
export var userStates = {
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated',
  DELETED: 'deleted'
};