import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.string.fixed";
import "core-js/modules/web.dom-collections.for-each";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { popperGenerator } from '@popperjs/core/lib/popper-lite';
import { PositioningStrategy } from '@popperjs/core/lib/types';
import popperOffsets from '@popperjs/core/lib/modifiers/popperOffsets';
import offset from '@popperjs/core/lib/modifiers/offset';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';
import arrow from '@popperjs/core/lib/modifiers/arrow';
import flip from '@popperjs/core/lib/modifiers/flip';
import hide from '@popperjs/core/lib/modifiers/hide';
import computeStyles from '@popperjs/core/lib/modifiers/computeStyles';
import applyStyles from '@popperjs/core/lib/modifiers/applyStyles';
import eventListeners from '@popperjs/core/lib/modifiers/eventListeners';
import { Boundary, RootBoundary, Placement } from '@popperjs/core/lib/enums';
import { portal } from '@directives/portal';
var createPopper = popperGenerator();
export default {
  name: 'Popper',
  inject: {
    $popperEventBus: {
      default: undefined
    }
  },
  directives: {
    portal: portal
  },
  props: {
    activePopperContent: {
      type: Boolean,
      default: false
    },
    reference: {
      type: Function,
      required: true
    },
    show: Boolean,
    transition: {
      type: String,
      default: 'slide-y-reverse-transition'
    },

    /**@type {Placement} */
    placement: {
      default: 'top'
    },
    offset: {
      type: Number,
      default: 10
    },
    offsetX: {
      type: Number,
      default: 0
    },

    /**@type {Boundary} */
    boundary: {
      default: 'clippingParents'
    },

    /**@type {RootBoundary} */
    rootBoundary: {
      default: 'viewport'
    },
    arrow: {
      type: Boolean,
      default: true
    },
    arrowSize: {
      type: Number,
      default: 12
    },
    arrowPadding: {
      type: Number,
      default: 4
    },
    flipOnOverflow: {
      type: Boolean,
      default: false
    },
    hideOnDetach: {
      type: Boolean,
      default: true
    },
    updateOnScroll: {
      type: Boolean,
      default: true
    },
    updateOnResize: {
      type: Boolean,
      default: true
    },
    zIndex: {
      type: Number,
      default: 8
    },
    fixed: Boolean
  },
  data: function data() {
    return {
      popperElement: undefined,
      arrowElement: undefined,
      portal: false
    };
  },
  computed: {
    popperOptions: function popperOptions() {
      var modifiers = [popperOffsets, _objectSpread(_objectSpread({}, preventOverflow), {}, {
        options: {
          boundary: this.boundary,
          rootBoundary: this.rootBoundary,
          padding: {
            right: 17,
            bottom: 17
          }
        }
      }), _objectSpread({}, computeStyles), _objectSpread({}, applyStyles)];

      if (this.offset) {
        modifiers.push(_objectSpread(_objectSpread({}, offset), {}, {
          options: {
            offset: [this.offsetX, this.offset]
          }
        }));
      }

      if (this.arrow && this.arrowElement) {
        modifiers.push(_objectSpread(_objectSpread({}, arrow), {}, {
          options: {
            element: this.arrowElement,
            padding: this.arrowPadding
          }
        }));
      }

      if (this.flipOnOverflow) {
        modifiers.push(flip);
      }

      if (this.hideOnDetach) {
        modifiers.push(hide);
      }

      if (this.show && (this.updateOnScroll || this.updateOnResize)) {
        modifiers.push(_objectSpread(_objectSpread({}, eventListeners), {}, {
          options: {
            scroll: this.updateOnScroll,
            resize: this.updateOnResize
          }
        }));
      }

      return {
        placement: this.placement,
        strategy: this.fixed ? 'fixed' : 'absolute',
        modifiers: modifiers
      };
    },
    arrowWrapperStyles: function arrowWrapperStyles() {
      var wh = "".concat(this.arrowSize, "px");
      return {
        width: wh,
        height: wh
      };
    },
    arrowStyles: function arrowStyles() {
      var wh = "".concat(this.arrowSize / Math.sqrt(2), "px");
      return {
        width: wh,
        height: wh
      };
    }
  },
  watch: {
    popperOptions: function popperOptions(v) {
      if (this.popperInstance) {
        this.popperInstance.setOptions(v);
      }
    },
    show: {
      immediate: true,
      handler: function handler(show) {
        if (show) {
          this.portal = true;
          this.createPopperInstance();
        } else this.eventBusUnsubscribe();
      }
    },
    popperElement: function popperElement() {
      if (this.show) {
        this.createPopperInstance();
      }
    }
  },
  methods: {
    destroyPopperInstance: function destroyPopperInstance() {
      if (this.popperInstance) {
        this.popperInstance.destroy();
        this.popperInstance = undefined;
      }

      this.eventBusUnsubscribe();
    },
    createPopperInstance: function createPopperInstance() {
      if (this.popperInstance || !this.popperElement) return false;
      this.popperInstance = createPopper(this.reference(), this.popperElement, this.popperOptions);
      this.eventBusSubscribe();
      return true;
    },
    update: function update() {
      if (this.popperInstance && this.show) {
        return this.popperInstance.update();
      }
    },
    forceUpdate: function forceUpdate() {
      if (this.popperInstance && this.show) {
        return this.popperInstance.forceUpdate();
      }
    },
    eventBusSubscribe: function eventBusSubscribe() {
      if (this.$popperEventBus) {
        this.$popperEventBus.$on('popper:triggerUpdate', this.update);
        this.$popperEventBus.$on('popper:triggerForceUpdate', this.forceUpdate);
      }
    },
    eventBusUnsubscribe: function eventBusUnsubscribe() {
      if (this.$popperEventBus) {
        this.$popperEventBus.$on('popper:triggerUpdate', this.update);
        this.$popperEventBus.$on('popper:triggerForceUpdate', this.forceUpdate);
      }
    }
  },
  mounted: function mounted() {
    this.popperElement = this.$refs.popper;
    this.arrowElement = this.$refs.arrow;
  },
  beforeDestroy: function beforeDestroy() {
    this.destroyPopperInstance();
  }
};