import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Icon from '@comp/Icon.vue';
import ErrorTooltip from '@comp/forms/ErrorTooltip.vue';
import InfoTooltip from '@comp/forms/InfoTooltip.vue';
import ErrorIcon from '!!url-loader?!@static/icons/s-error-cross.svg';
import SuccessIcon from '!!url-loader?!@static/icons/s-success.svg';
import ClosedEyeIcon from '!!url-loader?!@static/icons/s-eye-close.svg';
import OpenedEyeIcon from '!!url-loader?!@static/icons/s-eye-open.svg';
import CloseIcon from '!!url-loader?!@static/icons/s-cross.svg';
import { mask } from 'vue-the-mask';
export default {
  name: 'TextInput',
  directives: {
    mask: mask
  },
  components: {
    Icon: Icon,
    ErrorTooltip: ErrorTooltip,
    InfoTooltip: InfoTooltip
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    animatedPlaceholder: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    iconAppend: {
      type: String,
      default: ''
    },
    clearable: Boolean,
    unique: {
      type: Boolean,
      default: undefined
    },
    uniqueTrueText: {
      type: String,
      default: 'This username is available'
    },
    uniqueFalseText: {
      type: String,
      default: 'This username is already taken'
    },
    small: {
      type: Boolean,
      default: false
    },
    info: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    disabled: Boolean,
    inpMask: {
      default: ''
    },
    zIndexError: {
      type: Number,
      default: 8
    },
    fixed: Boolean,
    autocomplete: String
  },
  data: function data() {
    return {
      ErrorIcon: ErrorIcon,
      SuccessIcon: SuccessIcon,
      ClosedEyeIcon: ClosedEyeIcon,
      OpenedEyeIcon: OpenedEyeIcon,
      CloseIcon: CloseIcon,
      focus: false,
      showPassword: false
    };
  },
  computed: {
    inputType: function inputType() {
      return this.type === 'password' && this.showPassword ? 'text' : this.type;
    }
  },
  methods: {
    focusIn: function focusIn() {
      this.focus = true;
      this.showInfoButton = true;
    },
    focusOut: function focusOut() {
      this.focus = false;
      this.showInfoButton = false;
    }
  }
};