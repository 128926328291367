import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Popper from '@comp/base/Popper.vue';
import { VFadeTransition } from 'vuetify/lib';

var props = _objectSpread(_objectSpread({}, Popper.props), {}, {
  error: String,
  arrowSize: {
    type: Number,
    default: 9
  }
});

delete props.show;
export default {
  name: 'ErrorTooltip',
  props: props,
  watch: {
    error: function error(current, prev) {
      if (current && prev && this.$refs.popper) {
        this.$refs.popper.update();
      }
    }
  },
  render: function render(h) {
    var props = this.$props;
    return h(Popper, {
      class: 'error-tooltip',
      props: _objectSpread(_objectSpread({
        arrowSize: 1
      }, props), {}, {
        show: !!props.error
      }),
      ref: 'popper'
    }, [h(VFadeTransition, props.error ? [h('span', props.error)] : undefined)]);
  }
};