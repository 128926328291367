import "core-js/modules/es.number.constructor";

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Popper from '@comp/base/Popper.vue';
import Icon from '@comp/Icon.vue';
import InfoIcon from '!!url-loader?!@static/icons/s-info.svg';
import { VFadeTransition, VSlideYReverseTransition } from 'vuetify/lib';
import { portal } from '@directives/portal';
import { WindowWidth } from '@utils/window-width';
export default {
  name: 'InfoTooltip',
  directives: {
    portal: portal
  },
  components: {
    Popper: Popper,
    Icon: Icon,
    VFadeTransition: VFadeTransition,
    VSlideYReverseTransition: VSlideYReverseTransition
  },
  props: {
    activePopperContent: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,
    showActivator: {
      type: Boolean,
      default: true
    },
    info: String,
    offset: Number,
    flipOnOverflow: {
      type: Boolean,
      default: true
    },
    zIndex: {
      type: Number,
      default: 9
    },
    contentClass: [String, Array, Object],
    fixed: Boolean,
    iconSize: {
      type: Number,
      default: 16
    }
  },
  data: function data() {
    return {
      showInfo: false,
      showMobileInfo: false,
      InfoIcon: InfoIcon
    };
  },
  watch: {
    showMobileInfo: function showMobileInfo(val) {
      try {
        if (val) {
          document.querySelector('html').style.overflow = 'hidden';
        } else {
          document.querySelector('html').style.overflow = 'auto';
        }
      } catch (err) {}
    }
  },
  computed: {
    contentScopeId: function contentScopeId() {
      var _this$$parent;

      var scopeAttr = (_this$$parent = this.$parent) === null || _this$$parent === void 0 ? void 0 : _this$$parent.$options._scopeId;
      return scopeAttr ? _defineProperty({}, scopeAttr, true) : {};
    },
    showMobile: function showMobile() {
      return WindowWidth.mediaQuery['max-desktop'];
    }
  },
  methods: {
    mouseEnterPopper: function mouseEnterPopper() {
      if (this.activePopperContent) {
        this.showInfo = !this.disabled && !this.showMobile;
      }
    },
    mouseLeavePopper: function mouseLeavePopper() {
      if (this.activePopperContent) {
        this.showInfo = false;
      }
    }
  }
};