export var BLOCKING_STATUSES = {
  USER_BLOCKED: 'userBlocked',
  BLOCKED_BY_USER: 'blockedByUser',
  NO_RESTRICTIONS: 'noRestrictions',
  YOU: 'you'
};
export var FOLLOWING_STATUSES = {
  NOT_FOLLOWING: 'notFollowing',
  FOLLOWING: 'following',
  YOU: 'you'
};