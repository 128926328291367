export function getDefaultState() {
  var defaultState = {
    partnerBanners: {}
  };
  return defaultState;
}
export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    setPartnerBanners: function setPartnerBanners(state, payload) {
      state.partnerBanners = payload;
    }
  },
  getters: {
    getPartnerBanners: function getPartnerBanners(state) {
      return state.partnerBanners;
    }
  }
};