import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import Vuelidate from 'vuelidate';
import VueTelInput from 'vue-tel-input';
import PortalVue from 'portal-vue';
import Vuebar from 'vuebar';
import axios from 'axios';
import storeObject from '@store/store';
import TextareaAutosize from 'vue-textarea-autosize';
import { initAuthInterceptors, initAccessTokenExpireCheck, initRouterGuard, initLogout, initAuthSync } from '@utils/auth';
import { initUnsubscribeRoute } from '@utils/notifications';
import { initPayPalConnect } from '@utils/paypal';
import VModal from 'vue-js-modal';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import moment from 'moment';
import isEqual from 'lodash.isequal';
import SmoothVuebar from 'smooth-vuebar';
import Scrollbar from 'smooth-scrollbar';
import OverscrollPlugin from 'smooth-scrollbar/plugins/overscroll';
import Vue2TouchEvents from 'vue2-touch-events';
import VueClipboard from 'vue-clipboard2';
import contenteditable from 'vue-contenteditable';
Vue.use(contenteditable);
Vue.use(SmoothVuebar);
Vue.use(Vue2TouchEvents);
Scrollbar.use(OverscrollPlugin);
Vue.use(TextareaAutosize);
moment.updateLocale(moment.locale(), {
  week: {
    dow: 0 // Sunday is the first day of the week.

  }
});
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(Vuelidate);
Vue.use(VueTelInput);
Vue.use(PortalVue);
Vue.use(Vuebar);
Vue.use(VModal);
Vue.use(VueClipboard);
import App from './App.vue';
import routes from '@vue/router/routes';
var store = new Vuex.Store(_objectSpread({}, storeObject));
initAccessTokenExpireCheck(store);
initAuthInterceptors(axios, store);
var router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior: function scrollBehavior(to, from, savedPosition) {
    if (to.hash) return {
      selector: to.hash
    };
    if (isEqual([to.name, to.path], [from.name, from.path])) return undefined;
    return {
      x: 0,
      y: 0
    };
  }
});
router.beforeEach(function (to, from, next) {
  store.dispatch('routeLoadingStart');
  next();
});
router.afterEach(function (to, from) {
  store.dispatch('routeLoadingEnd');
});
initRouterGuard(router, store, {
  homePageName: 'Dashboard',
  loginPageName: 'Dashboard',
  joinPageName: 'Dashboard'
});
initLogout(router, store, {
  name: 'SingIn'
});
initAuthSync(router, store);
initUnsubscribeRoute(router, store, 'Unsubscribe');
initPayPalConnect(router);
var currentRoute = undefined;
var notReloadOnErroKey = '__do_not_reload_on_error__';
router.beforeEach(function (to, from, next) {
  currentRoute = to;
  next();
});
router.afterEach(function (to, from) {
  currentRoute = undefined;
  sessionStorage.removeItem(notReloadOnErroKey);
});
router.onError(function (error) {
  if (!currentRoute || sessionStorage.getItem(notReloadOnErroKey) === 'true') return;
  location.href = currentRoute.fullPath;
  sessionStorage.setItem(notReloadOnErroKey, 'true');
});
var vm = new Vue({
  router: router,
  store: store,
  vuetify: vuetify,
  components: {
    App: App
  },
  template: '<App></App>'
}).$mount('#app');