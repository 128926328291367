import Vue from 'vue';
export var WindowWidth = new Vue({
  data: function data() {
    return {
      width: window.innerWidth
    };
  },
  methods: {
    onResize: function onResize() {
      var _this = this;

      requestAnimationFrame(function () {
        _this.width = window.innerWidth;
      });
    }
  },
  computed: {
    mediaQuery: function mediaQuery() {
      return {
        'max-phone': this.width <= 575.98,
        'min-phone': this.width >= 576,
        'max-tablet': this.width <= 767.98,
        'min-tablet': this.width >= 768,
        'max-desktop': this.width <= 1199.98,
        'min-desktop': this.width >= 1200,
        'max-laptop': this.width <= 1280,
        'min-laptop': this.width >= 1280,
        'max-desktop-lg': this.width <= 1600,
        'min-desktop-lg': this.width >= 1921,
        'max-buy-pick-card-sml': this.width <= 1810
      };
    }
  },
  created: function created() {
    window.addEventListener('resize', this.onResize, {
      passive: true
    });
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.onResize, {
      passive: true
    });
  }
});