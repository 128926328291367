import "core-js/modules/es.object.assign";
import Vue from 'vue';
export function getDefaultState() {
  var defaultState = {
    pickStates: {}
  };
  return defaultState;
}
export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    /**
     *
     * @param {ReturnType<typeof getDefaultState>} state
     */
    updatePickState: function updatePickState(state, _ref) {
      var pickId = _ref.pickId,
          update = _ref.update;

      if (!state.pickStates[pickId]) {
        Vue.set(state.pickStates, pickId, {
          flipping: undefined,
          beforeFlip: undefined,
          flipResult: undefined,
          showResults: undefined,
          oddTab: undefined,
          activeSelectionIndex: undefined,
          isFavorite: undefined,
          isFavoriteLoading: undefined
        });
      }

      Object.assign(state.pickStates[pickId], update);
    }
  }
};