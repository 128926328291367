//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { VCheckbox } from 'vuetify/lib';
import ErrorTooltip from '@comp/forms/ErrorTooltip.vue';
import Icon from '@comp/Icon.vue';
import CheckIcon from '!!url-loader?!@static/icons/s-check.svg';
export default {
  name: 'CheckboxInput',
  components: {
    ErrorTooltip: ErrorTooltip,
    Icon: Icon,
    'v-checkbox': VCheckbox
  },
  props: {
    name: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    fixed: Boolean
  },
  data: function data() {
    return {
      CheckIcon: CheckIcon // value: false,

    };
  },
  computed: {
    stateValue: {
      get: function get() {
        return this.value;
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    getErrorReferenceElement: function getErrorReferenceElement() {
      return this.$refs.checkbox.$el.querySelector('input');
    }
  }
};