import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.slice";
import "core-js/modules/es.object.entries";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.string.ends-with";
import "core-js/modules/web.dom-collections.for-each";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var globalDefaultOptions = {
  decimalPlaces: 2,
  prefix: '',
  suffix: '',
  minusSign: true,
  plusSign: false,
  useGrouping: true
};

function mergeOptions(defaultOptions, options) {
  var res = _objectSpread({}, defaultOptions);

  if (options !== undefined) {
    Object.entries(options).filter(function (kv) {
      return kv !== undefined;
    }).forEach(function (kv) {
      return res[kv[0]] = kv[1];
    });
  }

  return res;
}
/**
 *
 * @param {typeof globalDefaultOptions} baseOptions
 * @returns {(n: number, options?: typeof globalDefaultOptions) => string}
 */


export function formatNumberFactory() {
  var baseOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
  var defaultOptions = mergeOptions(globalDefaultOptions, baseOptions);
  return function (n) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;

    var _mergeOptions = mergeOptions(defaultOptions, options),
        prefix = _mergeOptions.prefix,
        suffix = _mergeOptions.suffix,
        decimalPlaces = _mergeOptions.decimalPlaces,
        minusSign = _mergeOptions.minusSign,
        plusSign = _mergeOptions.plusSign,
        useGrouping = _mergeOptions.useGrouping;

    if (isNaN(n)) {
      return 'N/A';
    }

    var _n = n;

    if (decimalPlaces !== true) {
      var pow = Math.pow(10, decimalPlaces);
      _n = Math.round(n * pow) / pow;
    }

    if (n === 0) n = Math.abs(n);
    if (n < 0 && !minusSign) _n = "(".concat((-1 * _n).toLocaleString('en-US', {
      useGrouping: useGrouping
    }), ")");else if (n > 0 && plusSign) _n = '+' + _n.toLocaleString('en-US', {
      useGrouping: useGrouping
    });else _n = _n.toLocaleString('en-US', {
      useGrouping: useGrouping
    });
    return prefix + _n + suffix;
  };
}
export var formatNumber = formatNumberFactory();
export var oddNumbersFormatter = formatNumberFactory({
  plusSign: true,
  useGrouping: false
});
export var statisticNumbersFormatter = formatNumberFactory({
  minusSign: false,
  decimalPlaces: 0
});
/**
 *
 * @param {number} n
 */

export function formatPrice(n) {
  var minus = n < 0;
  var str = Math.abs(n).toLocaleString('en-US', {
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  if (str.endsWith('.00')) str = str.slice(0, -3);
  return '$' + (minus ? '(' : '') + str + (minus ? ')' : '');
}