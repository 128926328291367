import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import SingIn from '@comp/pages/auth/SingIn.vue';
import Join from '@comp/pages/auth/Join.vue';
import VButton from '@comp/parts/VButton.vue';
export default {
  components: {
    SingIn: SingIn,
    VButton: VButton,
    Join: Join
  },
  data: function data() {
    return {
      showLogin: true
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState('auth', ['authPopup'])), {}, {
    modalStyle: function modalStyle() {
      var innerWidth = window.innerWidth;

      if (innerWidth < 768) {
        return {
          width: '100%',
          height: '100%'
        };
      } else {
        return {
          width: '608px',
          height: 'auto'
        };
      }
    }
  }),
  watch: {
    authPopup: {
      deep: true,
      immediate: true,
      handler: function handler(val) {
        if (val.show) {
          this.$modal.show('login-modal');
          document.getElementsByTagName('html')[0].classList.add('popup-opened');
        } else {
          this.hideModal();
        }
      }
    }
  },
  methods: {
    hideModal: function hideModal() {
      this.$modal.hide('login-modal');
      document.getElementsByTagName('html')[0].classList.remove('popup-opened');
    }
  }
};