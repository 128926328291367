//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { VProgressCircular } from 'vuetify/lib';
import Icon from '@comp/Icon.vue';
import CrossIcon from '!!url-loader?!@static/icons/s-cross-sml.svg';
import ArrowIcon from '!!url-loader?!@static/icons/s-arrow-right.svg';
import StarIcon from '!!url-loader?!@static/icons/s-star.svg';
import CopyIcon from '!!url-loader?!@static/icons/s-copy.svg';
import BlockIcon from '!!url-loader?!@static/icons/block.svg';
import ErrorTooltip from '@comp/forms/ErrorTooltip.vue';
export default {
  name: 'VButton',
  components: {
    ErrorTooltip: ErrorTooltip,
    VProgressCircular: VProgressCircular,
    Icon: Icon
  },
  props: {
    title: String,
    hoverTitle: String,
    loading: Boolean,
    disabled: Boolean,
    whyDisabled: String,
    type: {
      type: String,
      default: 'fill'
    },
    fixed: Boolean,
    isDisabledTransparent: Boolean
  },
  data: function data() {
    return {
      hovered: false
    };
  },
  computed: {
    icon: function icon() {
      if (this.type === 'cross') return CrossIcon;
      if (this.type === 'with-arrow') return ArrowIcon;
      if (this.type === 'favorites') return StarIcon;
      if (this.type === 'copy') return CopyIcon;
      if (this.type === 'block') return BlockIcon;
      return undefined;
    },
    error: function error() {
      if (this.hovered && this.disabled && !!this.whyDisabled) return this.whyDisabled;
    },
    titleOnHover: function titleOnHover() {
      return this.hoverTitle || this.title;
    }
  },
  methods: {
    handleClick: function handleClick(e) {
      this.hovered = true;

      if (!this.disabled && !this.loading) {
        this.$emit('click', e);
      }
    }
  }
};